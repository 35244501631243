(window as any).__zone_symbol__UNPATCHED_EVENTS = [
  'scroll',
  'mousemove',
  'touchmove',
  'pointerup',
  'pointerdown',
  'pointermove',
  'pointerup',
  'pointercancel',
  'message',
];
// disable patching requestAnimationFrame
(window as any).__Zone_disable_requestAnimationFrame = true;
